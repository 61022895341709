<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>虚拟用户</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关注日期">
                <el-date-picker
                  style="width: 100%"
                  v-model="searchtime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
            <el-button icon="el-icon-plus" size="medium" type="primary" @click="add_page"
              >添加
            </el-button>
            <el-button
              v-if="is_auth('user.fictitious.isaddall')"
              icon="el-icon-plus"
              size="medium"
              type="primary"
              @click="batchAdd_page"
              >批量添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium">
        <el-table-column prop="user_uuid" label="用户uuid" width="120"> </el-table-column>
        <el-table-column prop="avatar_url" label="头像" width="80">
          <template slot-scope="scope">
            <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="昵称" min-width="150"> </el-table-column>
        <el-table-column prop="phone" label="手机号" width="200"> </el-table-column>
        <el-table-column prop="gender" label="性别" width="160">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.gender === 0" type="info">未知</el-tag>
            <el-tag size="mini" v-if="scope.row.gender === 1">男</el-tag>
            <el-tag size="mini" v-if="scope.row.gender === 2" type="danger">女</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="关注时间" width="150">
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="150">
        </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="220"> </el-table-column> -->
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "虚拟用户",
      issearch: false,
      searchtime: null, //时间
      loading: true,
      search: {}, // 搜索条件
      tableData: [], // 列表内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    //初始化
    init() {
      this.searchtime = null;
      this.search = {
        nick_name: "",
        phone: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "user.fictitious.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.$message.error(json.message);
        }
      });
    },
    // 添加
    add_page() {
      this.$router.push({ path: "/user/fictitious/add" });
    },
    // 跳转批量添加
    batchAdd_page() {
      this.$router.push({ path: "/user/fictitious/batchAdd" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.el-dialog__wrapper {
  z-index: 99999;
}
</style>
